/**
 * * Every Service implements this class
 */
export default class BaseService {
  // TODO: Lidar com envVar para hostname, futuramente talvez isto deverá ser um plugin para que seja possível ler .env
  static readonly hostname =
    'https://multichannel-sendit.multichannel-qa.sendit.pt'

  static readonly apiHostName = 'https://api.multichannel-qa.sendit.pt'

  private readonly basePath: string

  constructor(basePath: string) {
    this.basePath = basePath
  }

  protected getFullBaseUrl(): string {
    return `${BaseService.hostname}/services/${this.basePath}`
  }

  protected getFullApiBaseUrl(): string {
    return `${BaseService.apiHostName}/${this.basePath}`
  }
}
